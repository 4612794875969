import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPopups, PopupsSymbol } from './index'
import { CurrentRouteInfoSymbol, ILinkClickHandler } from '@wix/thunderbolt-symbols'
import { ICurrentRouteInfo } from 'feature-router'

const popupLinkFactory = (popupApi: IPopups, currentRouteInfo: ICurrentRouteInfo): ILinkClickHandler => ({
	handleClick: (anchor) => {
		const popupId = anchor.getAttribute('data-popupid')
		if (popupId) {
			popupApi.openPopupPage(popupId)
			return true
		}
		const { relativeUrl } = currentRouteInfo.getCurrentRouteInfo()!
		const isLightboxOpen = !!popupApi.getCurrentPopupId()
		const isLinkToCurrentPage = relativeUrl === anchor.getAttribute('href')
		if (isLightboxOpen && isLinkToCurrentPage) {
			popupApi.closePopupPage()
			return true
		}

		return false
	},
})

export const PopupLink = withDependencies([PopupsSymbol, CurrentRouteInfoSymbol], popupLinkFactory)
